import { useStaticQuery, graphql } from "gatsby";

interface OpenGraphLogo {
  logo: {
    childImageSharp: {
      resize: {
        src: string;
      };
    };
  };
}

export const useOpenGraphLogo = (): string => {
  const { logo } = useStaticQuery<OpenGraphLogo>(graphql`
    query {
      logo: file(relativePath: { eq: "oslobrukthandelforening.png" }) {
        childImageSharp {
          resize(width: 500) {
            src
          }
        }
      }
    }
  `);
  return logo.childImageSharp.resize.src;
};
