import React from "react";
import OsloBrukthandelforening from "../images/oslobrukthandelforening.inline.svg";

const Logo: React.FC = () => {
  return (
    <div className="ml-auto mr-auto" style={{ maxWidth: "14rem" }}>
      <OsloBrukthandelforening />
    </div>
  );
};

export default Logo;
