import React from "react";
import PropTypes from "prop-types";

export interface GridProps {
  // TODO: Use this line instead of string
  //tag?: keyof React.ReactHTML;
  tag?: string;
  children: React.ReactNode[] | React.ReactNode;
  className?: string;
}

const Grid: React.FC<GridProps> = ({ tag = "div", children, className = "" }) => {
  const Wrapper = tag as "div";
  return <Wrapper className={className}>{children}</Wrapper>;
};

Grid.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default Grid;
