import React from "react";
import PropTypes from "prop-types";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";

import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { useMembers } from "../hooks/useMembers";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Map from "../components/Map";
import Members from "../components/Members";
import Block from "../grid/Block";

export interface PageProps {
  path: string;
  pageContext: {
    intl: {
      language: string;
      languages: string[];
    };
  };
}

const IndexPage: React.FC<PageProps> = ({ pageContext }) => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: "title" });
  const {
    intl: { language, languages },
  } = pageContext;
  const { email, facebook } = useSiteMetadata();
  const members = useMembers();
  return (
    <Layout
      title={title}
      description={intl.formatMessage({ id: "description" })}
      aside={
        <Map
          title={intl.formatMessage({ id: "map" })}
          src="https://www.google.com/maps/d/embed?mid=18NxjmbykcNdwSbN7MGIEHTKKR7-zJi92"
        />
      }
    >
      <Seo title={title} language={language} languages={languages} />
      <Block>
        <p>
          <a href={`mailto:${email}`}>{email}</a>
        </p>
        <p>
          <a href={facebook}>
            <FormattedMessage id="facebook" />
          </a>
        </p>
      </Block>
      <Block>
        <Members
          title={intl.formatMessage({ id: "members" })}
          description={intl.formatMessage({ id: "membersDescription" })}
          members={members}
        />
      </Block>
    </Layout>
  );
};

export const PagePropTypes = {
  path: PropTypes.string.isRequired,
  pageContext: PropTypes.shape({
    intl: PropTypes.shape({
      language: PropTypes.string.isRequired,
      languages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired,
  }).isRequired,
};

IndexPage.propTypes = PagePropTypes;

export default IndexPage;
