import { useStaticQuery, graphql } from "gatsby";

type SiteMetadata = {
  siteUrl: string;
  name: string;
  foundingDate: string;
  email: string;
  facebook: string;
};

interface SiteMetadataQuery {
  site: {
    siteMetadata: SiteMetadata;
  };
}

export const useSiteMetadata = (): SiteMetadata => {
  const {
    site: {
      siteMetadata: { siteUrl = "", name = "", foundingDate = "", email = "", facebook = "" } = {},
    } = {},
  } = useStaticQuery<SiteMetadataQuery>(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          name
          foundingDate
          email
          facebook
        }
      }
    }
  `);
  return {
    siteUrl,
    name,
    foundingDate,
    email,
    facebook,
  };
};
