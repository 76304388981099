import React from "react";
import PropTypes from "prop-types";

interface MapProps {
  title: string;
  src: string;
}

const Map: React.FC<MapProps> = ({ title, src }) => (
  <div className="relative overflow-hidden w-full h-auto" style={{ paddingBottom: "100%" }}>
    <iframe
      className="absolute pin-t pin-l w-full h-full"
      title={title}
      src={src}
      width="640"
      height="640"
      loading="lazy"
    />
  </div>
);

Map.propTypes = {
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export default Map;
