import React from "react";
import PropTypes from "prop-types";
import Grid, { GridProps } from "./Grid";

const Block: React.FC<GridProps> = ({ tag = "div", children, className = "" }) => (
  <Grid tag={tag} className={`mb-10 ${className}`}>
    {children}
  </Grid>
);

Block.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default Block;
