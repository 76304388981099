import React from "react";
import PropTypes from "prop-types";
import Linkify from "react-linkify";
import { Placemark } from "../../packages/gatsby-transformer-kml-placemarks/gatsby-node";

const formatText = (text: string) =>
  text.includes("instagram.com") ? text.replace(/(^\w+:|^)\/\/www\./, "") : text;

interface MembersProps {
  title: string;
  description?: string;
  members: Placemark[];
}

const Members: React.FC<MembersProps> = ({ title, description, members }) => (
  <>
    <h2>{title}</h2>
    <p>{description}</p>
    {members.map(({ name, description }) => (
      <div key={name}>
        <h3>{name}</h3>
        <p>
          <Linkify textDecorator={formatText}>{description}</Linkify>
        </p>
      </div>
    ))}
  </>
);

Members.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      latitude: PropTypes.string.isRequired,
      longitude: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default Members;
