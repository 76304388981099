import React from "react";
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl";
import { useIntl } from "gatsby-plugin-intl";

const LanguageSwitcher: React.FC = () => {
  const intl = useIntl();
  return (
    <IntlContextConsumer>
      {({ languages, language: currentLanguage }: { languages: string[]; language: string }) =>
        languages
          .filter((language) => language !== currentLanguage)
          .map((language) => (
            <button
              lang={language}
              key={language}
              onClick={() => changeLocale(language)}
              className="bg-white text-xs font-sans py-1 px-2 rounded"
            >
              {intl.formatMessage({ id: language })}
            </button>
          ))
      }
    </IntlContextConsumer>
  );
};

export default LanguageSwitcher;
