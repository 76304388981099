import React from "react";
import PropTypes from "prop-types";

import "../styles/layout.css";
import Container from "../grid/Container";
import Row from "../grid/Row";
import Column from "../grid/Column";
import Logo from "./Logo";
import LanguageSwitcher from "./LanguageSwitcher";

interface LayoutProps {
  title: string;
  description: string;
  aside?: React.ReactNode;
  children: React.ReactNode[] | React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ title, description, aside, children }) => (
  <div className="bg-white xl:my-10 xl:rounded-sm xl:shadow-2xl py-3 xl:pt-10 max-w-6xl ml-auto mr-auto">
    <Container>
      <Row tag="header">
        <Column className="sm:w-1/4">
          <Logo />
          <div className="absolute top-0 right-0 mt-0 mr-0 sm:mt-1 sm:mr-1 md:mt-2 md:mr-2 lg:mt-2 lg:mr-3 xl:mt-4 xl:mr-4">
            <LanguageSwitcher />
          </div>
        </Column>
        <Column className="sm:w-3/4">
          <h1 className="sm:mt-6">{title}</h1>
          <p>{description}</p>
        </Column>
      </Row>
      <Row tag="main">
        <Column className="sm:w-3/4 sm:ml-auto">{children}</Column>
      </Row>
      {aside && (
        <Row tag="aside">
          <Column className="w-full">{aside}</Column>
        </Row>
      )}
    </Container>
  </div>
);

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  aside: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Layout;
