import { useStaticQuery, graphql } from "gatsby";
import { Placemark } from "../../packages/gatsby-transformer-kml-placemarks/gatsby-node";

interface SiteMetadataQuery {
  allPlacemark: { nodes: Placemark[] };
}

export const useMembers = (): Placemark[] => {
  const {
    allPlacemark: { nodes: members },
  } = useStaticQuery<SiteMetadataQuery>(graphql`
    query {
      allPlacemark(
        sort: { fields: name, order: ASC }
        filter: { sourceInstanceName: { eq: "members" } }
      ) {
        nodes {
          name
          description
          latitude
          longitude
        }
      }
    }
  `);
  return members;
};
