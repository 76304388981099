import React from "react";
import PropTypes from "prop-types";
import Grid, { GridProps } from "./Grid";

const Row: React.FC<GridProps> = ({ tag = "div", children, className = "" }) => (
  <Grid tag={tag} className={`sm:flex -mx-3 ${className}`}>
    {children}
  </Grid>
);

Row.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default Row;
