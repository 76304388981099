import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useIntl } from "gatsby-plugin-intl";
import { useOpenGraphLogo } from "../hooks/useOpenGraphLogo";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import { useMembers } from "../hooks/useMembers";

interface SeoProps {
  title: string;
  description?: string;
  image?: string;
  language: string;
  languages: string[];
}

const Seo: React.FC<SeoProps> = ({ title, description, image, language, languages }) => {
  const intl = useIntl();
  const logo = useOpenGraphLogo();
  const members = useMembers();
  const { siteUrl, name, foundingDate, email, facebook } = useSiteMetadata();
  const metaDescription = description || intl.formatMessage({ id: "description" });
  const ogImage = image || logo;
  const schemaOrgJSONLD = [
    {
      "@context": "https://schema.org",
      "@id": `${siteUrl}#organization`,
      "@type": "Organization",
      name,
      legalName: name,
      url: siteUrl,
      logo: `${siteUrl}${ogImage}`,
      foundingDate,
      contactPoint: {
        "@type": "ContactPoint",
        email,
      },
      sameAs: [facebook],
      members: members.map((member) => ({
        "@type": "Store",
        "@id": `${member.description}#organization`,
      })),
    },
    ...members.map((member) => ({
      "@context": "https://schema.org",
      "@type": "Store",
      "@id": `${member.description}#organization`,
      name: member.name,
      geo: {
        "@type": "GeoCoordinates",
        latitude: member.latitude,
        longitude: member.longitude,
      },
      url: member.description,
      image: `${siteUrl}${ogImage}`,
      memberOf: {
        "@type": "Organization",
        "@id": `${siteUrl}#organization`,
      },
    })),
  ];

  return (
    <Helmet titleTemplate={`%s`}>
      <html lang={language} />
      <body className="bg-gray-300 xl:py-10" />
      <title>{intl.formatMessage({ id: "title" })}</title>
      {languages.map((language) => (
        <link rel="alternate" hrefLang={language} href={`/${language}/`} key={language} />
      ))}
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:image" content={ogImage} />
      <meta name="og:type" content="website" />
      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  language: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default Seo;
