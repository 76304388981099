import React from "react";
import PropTypes from "prop-types";
import Grid, { GridProps } from "./Grid";

const Column: React.FC<GridProps> = ({ tag = "div", children, className = "" }) => (
  <Grid tag={tag} className={`px-3 ${className}`}>
    {children}
  </Grid>
);

Column.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

export default Column;
